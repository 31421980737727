import { helpers, maxLength, minLength, required } from "@vuelidate/validators";

export default {
  registration: {
    emailValue: "Email",
    captchaValue: "Captcha",
    emailCode: "PIN код",
  },
  myProfileData: {
    last_name: "Фамилия",
    first_name: "Имя",
    middle_name: "Отчество",
    birthday: "Дата рождения",
    gender: "Пол",
    education_region_id: "Регион обучения",
    education_city_id: "Город обучения",
    phone: "Номер телефона",
    about: "Краткая информация о себе",
  },
  educationData: {
    education_region_id: "Регион обучения",
    education_city_id: "Город обучения",
    vuz_type: "Тип учебного заведения",
    is_not_student: "Текущий учебный статус",
    educational_establishment_id: "Учебное заведение",
    faculty_id: "Факультет/институт",
    speciality_education: "Специальность",
    group: "Группа",
    year_ending_education: "Год окончания учебного заведения",
    level_education: "Уровень образования",
    document_student_billet: "Студенческий билет",
    course_level: "Курс",
  },
  personalDataRussian: {
    series: "Серия паспорта",
    number: "Номер паспорта",
    issue_at: "Дата выдачи паспорта",
    who_issued_document: "Кем выдан паспорт",
    division_code: "Код подразделения",
    place_birth: "Место рождения",
    place_registration: "Место жительства: по паспорту",
    place_residence: "Место жительства: фактическое",
    inn: "ИНН",
    document_passport_main_page: "Разворот паспорта 2-3 стр.",
    document_passport_register_page:
      "Разворот паспорта с регистрацией 4-5 стр.",
    document_passport_old_version_page:
      "Разворот с ранее выданными паспортами 19 стр. паспорта",
    document_passport_6_7_page: "Разворот паспорта 6-7 стр.",
    document_inn: "СКАН ИНН",
  },
  personalDataForeign: {
    citizenship: "Гражданство",
    series_number: "Номер и серия паспорта",
    issue_at: "Дата выдачи паспорта",
    migration_card_series: "Миграционная карта: серия",
    migration_card_number: "Номер миграционной карты",
    migration_card_issue_at: "Даты выдачи миграционных карт",
    migration_card_expiration_at: "Срок действия миграционной карты ",
    registration_list_place_at:
      "Регистрационный лист: даты выдачи регистрационного листа",
    registration_list_until_at: "Срок действия регистрационного листа",
    place_registration: "Адрес регистрации регистрационного листа",
    inn: "Номер ИНН",
    tin: "Номер TIN (при наличии)",
    place_issue: "Кем выдан паспорт",
    document_foreign_passport_main_page:
      "Скан разворота с фото иностранного паспорта",
    document_foreign_passport_registration_page:
      "Скан разворота с иностранной пропиской",
    document_foreign_passport_translate:
      "Скан нотариально заверенного перевода иностранного паспорта (главная страница)",
    document_migration_card_front: "Скан миграционной карты (лицевая часть)",
    document_migration_card_back: "Скан миграционной карты (задняя часть)",
    document_registration_sheet_front:
      "Скан миграционной карты (лицевая часть)",
    document_registration_sheet_back: "Скан миграционной карты (задняя часть)",
    document_inn: "Скан ИНН",
    document_tin: "Скан TIN",
  },
  appeal: {
    message: "Ваше обращение",
  },
  performanceDataRecordBook: {
    document_student_record_book_main_page: "Разворот зачётной книжки с фото",
    document_student_record_book_session_pages_one:
      "Предпоследняя завершённая сессия",
    document_student_record_book_session_pages_two:
      "Последняя завершённая сессия",
    document_reference: "Справка с места учебы",
  },
  performanceDataReference: {
    document_reference: "Справка с места учебы",
  },
  tgProject: {
    excellentStudent: {
      document_student_record_book_main_page: "Разворот зачетной книжки с фото",
      document_student_record_book_session_pages_one: "Текущая сессия",
      document_student_record_book_session_pages_two: "Предыдущая сессия 1",
    },
    socialActive: {
      firstCourse: {
        document_reference: "Справка с места учебы",
      },
      otherCourses: {
        document_student_record_book_main_page:
          "Разворот зачетной книжки с фото",
        document_student_record_book_session_pages_two: "Текущая сессия",
      },
    },
  },
  portfolioData: {
    year: "Год",
    title: "Назание файла",
    image: "Диплом/грамота",
  },
  projectProfileData: {
    vuz_type: "Тип учебного заведения",
    last_name: "Фамилия",
    first_name: "Имя",
    middle_name: "Отчество",
    phone: "Номер телефона",
    about: "Краткая информация о себе",
    educational_establishment_id: "Учебное заведение",
    faculty_id: "Факультет/институт",
    group: "Группа",
    course_level: "Курс",
    year_ending_education: "Год окончания учебного заведения",
    level_education: "Уровень образования",
    speciality_education: "Специальность",
  },
  achievementsData: {
    achievement: "Достижение",
    activityField: "Область деятельности",
    criterion: "Критерий",
    level: "Уровень",
    status: "Статус",
  },
  studentYearNominationsData: {
    project_tree_id: "Номинация",
  },
  studentYearProfileData: {
    vuz_type: "Тип учебного заведения",
    last_name: "Фамилия",
    first_name: "Имя",
    phone: "Номер телефона",
    educational_establishment_id: "Учебное заведение",
    faculty_id: "Факультет/институт",
    course_level: "Курс",
    group: "Группа",
    level_education: "Уровень образования",
  },
  studentYearCollectiveData: {
    project_name: "Наименование коллектива (проекта)",
    project_year: "Год создания",
    project_maintainer: "ФИО руководителя коллектива (проекта)",
    project_maintainer_phone: "Номер телефона",
  },
  studentYearPortfolioData: {
    portfolio: "Портфолио",
    resume: "Резюме",
  },
  housingStockProfile: {
    vuz_type: "Тип учебного заведения",
    is_not_student: "Текущий учебный статус",
    last_name: "Фамилия",
    first_name: "Имя",
    middle_name: "Отчество",
    birthday: "Дата рождения",
    educational_establishment_id: "Учебное заведение",
    faculty_id: "Факультет/институт",
    speciality_education: "Специальность",
    group: "Группа",
    phone: "Номер телефона",
    year_ending_education: "Год окончания учебного заведения",
    href_vk_publication_project: "Ссылка на публикацию",
    level_education: "Уровень образования",
    course_level: "Курс",
  },
  personalDataHS: {
    series: "Серия паспорта",
    number: "Номер паспорта",
    issue_at: "Дата выдачи паспорта",
    who_issued_document: "Кем выдан паспорт",
    division_code: "Код подразделения",
    place_birth: "Место рождения",
    place_registration: "Место жительства: по паспорту",
    place_residence: "Место жительства: фактическое",
    document_passport_main_page: "Разворот паспорта 2-3 стр.",
    document_passport_register_page:
      "Разворот паспорта с регистрацией 4-5 стр.",
    document_passport_6_7_page: "Разворот паспорта 6-7 стр.",
  },
  tracksLF: {
    first_direction_id: "Образовательный трек (Приоритет 1)",
    second_direction_id: "Образовательный трек (Приоритет 2)",
    third_direction_id: "Образовательный трек (Приоритет 3)",
    first_team: "Команда (Приоритет 1)",
    second_team: "Команда (Приоритет 2)",
    third_team: "Команда (Приоритет 3)",
  },
  profileLF: {
    last_name: "Фамилия",
    first_name: "Имя",
    middle_name: "Отчество",
    education_region_id: "Регион обучения",
    education_city_id: "Город обучения",
    phone: "Номер телефона",
    course_level: "Курс",
    educational_establishment_id: "Учебное заведение",
    faculty_id: "Факультет/институт",
    speciality_education: "Специальность",
    group: "Группа",
    year_ending_education: "Год окончания учебного заведения",
    level_education: "Уровень образования",
    vuz_type: "Тип учебного заведения",
  },
  infoLF: {
    // video_card: "Видео-визитка участника",
    community_represent: "Представляемое сообщество",
    community_represent_other: "Представляемое сообщество (другое)",
    community_represent_name: "Название представляемого сообщества",
    position_organization: "Позиция в организации",
    position_organization_other: "Позиция в организации (другое)",
    what_does_student_mean_you: "Значение студенчества",
    purpose_travel: "Цель поездки",
    your_main_achievements: "Главные достижения",
    hear_about_forum: "Откуда узнали о форуме",
    hear_about_forum_other: "Откуда узнали о форуме (другое)",
    food_type: "Предпочтение в питании",
    clothing_size: "Размер одежды",
    health_features: "Особенности здоровья",
  },
};
