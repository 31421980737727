import {
  helpers,
  required,
  email,
  numeric,
  minLength,
  maxLength,
  minValue,
  maxValue,
  url,
} from "@vuelidate/validators";
import moment from "moment";
export default {
  registration: {
    emailValue: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      email: helpers.withMessage("Неправильный формат", email),
    },
    captchaValue: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        maxLength(4)
      ),
    },
  },
  myProfileData: {
    last_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    first_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    middle_name: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    birthday: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    gender: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Неверное количество символов",
        minLength(17)
      ),
      maxLength: helpers.withMessage(
        "Неверное количество символов",
        maxLength(17)
      ),
    },
    about: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символов",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 2000 символов",
        maxLength(2000)
      ),
    },
  },
  educationData: {
    education_region_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    education_city_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    educational_establishment_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    faculty_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    course_level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    speciality_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 100 символов",
        maxLength(100)
      ),
    },
    group: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 10 символов",
        maxLength(10)
      ),
    },
    year_ending_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    level_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    // document_student_billet: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
  },
  vuz_type: {
    required: helpers.withMessage("Обязательно к заполнению", required),
  },
  is_not_student: {
    required: helpers.withMessage("Обязательно к заполнению", required),
  },
  personalDataRussian: {
    series: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        maxLength(4)
      ),
    },
    number: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 6 символов",
        minLength(6)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 6 символов",
        maxLength(6)
      ),
    },
    issue_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    who_issued_document: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    division_code: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 6 символов",
        minLength(7)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 6 символов",
        maxLength(7)
      ),
    },
    place_birth: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    place_registration: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    place_residence: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    inn: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 12 символов",
        minLength(12)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 12 символов",
        maxLength(12)
      ),
    },
    // document_passport_main_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_passport_register_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_passport_old_version_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_passport_6_7_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_inn: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
  },
  personalDataForeign: {
    citizenship: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    series_number: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 6 символов",
        minLength(6)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 12 символов",
        maxLength(12)
      ),
    },
    issue_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    migration_card_series: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        maxLength(4)
      ),
    },
    migration_card_number: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 7 символов",
        minLength(7)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 9 символов",
        maxLength(9)
      ),
    },
    migration_card_issue_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    migration_card_expiration_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    registration_list_place_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    registration_list_until_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    place_registration: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    inn: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 12 символов",
        minLength(12)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 12 символов",
        maxLength(12)
      ),
    },
    tin: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 9 символов",
        minLength(9)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 32 символов",
        maxLength(32)
      ),
    },
    place_issue: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    // document_foreign_passport_main_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_foreign_passport_registration_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_foreign_passport_translate: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_migration_card_front: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_migration_card_back: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_registration_sheet_front: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_registration_sheet_back: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_inn: {},
    // document_tin: {},
  },
  appeal: {
    message: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 2 символов",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 1000 символов",
        maxLength(1000)
      ),
    },
  },
  performanceDataRecordBook: {
    document_student_record_book_main_page: {
      required: helpers.withMessage("Файл должен быть загружен", required),
    },
    document_student_record_book_session_pages_one: {
      required: helpers.withMessage("Файл должен быть загружен", required),
    },
    document_student_record_book_session_pages_two: {},
    document_reference: {},
  },
  performanceDataReference: {
    document_student_record_book_main_page: {},
    document_student_record_book_session_pages_one: {},
    document_student_record_book_session_pages_two: {},
    document_reference: {
      required: helpers.withMessage("Файл должен быть загружен", required),
    },
  },
  tgProject: {
    excellentStudent: {
      document_student_record_book_main_page: {
        required: helpers.withMessage("Файл должен быть загружен", required),
      },
      document_student_record_book_session_pages_one: {
        required: helpers.withMessage("Файл должен быть загружен", required),
      },
      document_student_record_book_session_pages_two: {
        required: helpers.withMessage("Файл должен быть загружен", required),
      },
      document_reference: {},
    },
    socialActive: {
      firstCourse: {
        document_reference: {
          required: helpers.withMessage("Файл должен быть загружен", required),
        },
        document_student_record_book_main_page: {},
        document_student_record_book_session_pages_one: {},
        document_student_record_book_session_pages_two: {},
      },
      otherCourses: {
        document_reference: {},
        document_student_record_book_main_page: {
          required: helpers.withMessage("Файл должен быть загружен", required),
        },
        document_student_record_book_session_pages_one: {
          required: helpers.withMessage("Файл должен быть загружен", required),
        },
        document_student_record_book_session_pages_two: {},
      },
    },
  },
  portfolioData: {
    year: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    title: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 50 символов",
        maxLength(50)
      ),
    },
    image: {
      required: helpers.withMessage("Файл должен быть загружен", required),
    },
  },
  projectProfileData: {
    last_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    first_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    middle_name: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    educational_establishment_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    faculty_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    about: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символов",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 2000 символов",
        maxLength(2000)
      ),
    },
    course_level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    speciality_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 100 символов",
        maxLength(100)
      ),
    },
    year_ending_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    level_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    group: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символa",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 10 символов",
        maxLength(10)
      ),
    },
  },
  achievementsData: {
    // achievement: {
    //   required: helpers.withMessage("Обязательно к заполнению", required),
    // },
    activityField: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    criterion: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    status: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  studentYearNominationsData: {
    project_tree_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  studentYearProfileData: {
    last_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    first_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    middle_name: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    educational_establishment_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    faculty_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    level_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    group: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 10 символов",
        maxLength(10)
      ),
    },
    course_level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  studentYearCollectiveData: {
    project_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 2 символов",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    project_year: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        maxLength(4)
      ),
      minValue: helpers.withMessage(
        "Не должно быть раньше 1900 года",
        minValue(1900)
      ),
      maxValue: helpers.withMessage(
        "Не должно быть позже текущего года",
        maxValue(moment().format("YYYY"))
      ),
      numeric: helpers.withMessage("Должно быть числом", numeric),
    },
    project_maintainer: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 50 символов",
        maxLength(50)
      ),
    },
    project_maintainer_phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  studentYearPortfolioData: {
    portfolio: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    resume: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  housingStockProfile: {
    last_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    first_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    middle_name: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    birthday: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    educational_establishment_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Неверное количество символов",
        minLength(17)
      ),
      maxLength: helpers.withMessage(
        "Неверное количество символов",
        maxLength(17)
      ),
    },
    year_ending_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    faculty_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    course_level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    speciality_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 100 символов",
        maxLength(100)
      ),
    },
    group: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 10 символов",
        maxLength(10)
      ),
    },
    level_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    href_vk_publication_project: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  personalDataHS: {
    series: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        minLength(4)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 4 символов",
        maxLength(4)
      ),
    },
    number: {
      numeric,
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 6 символов",
        minLength(6)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 6 символов",
        maxLength(6)
      ),
    },
    issue_at: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        minLength(10)
      ),
      maxLength: helpers.withMessage(
        "Поле должно состоять из 8 символов",
        maxLength(10)
      ),
    },
    who_issued_document: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    division_code: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 6 символов",
        minLength(7)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 6 символов",
        maxLength(7)
      ),
    },
    place_birth: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    place_registration: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    place_residence: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 255 символов",
        maxLength(255)
      ),
    },
    // document_passport_main_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_passport_register_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
    // document_passport_6_7_page: {
    //   required: helpers.withMessage("Файл должен быть загружен", required),
    // },
  },
  tracksLF: {
    first_direction_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    second_direction_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    third_direction_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    first_team: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    second_team: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    third_team: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  profileLF: {
    last_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    first_name: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    middle_name: {
      minLength: helpers.withMessage(
        "Поле должно быть не менее 1 символа",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 30 символов",
        maxLength(30)
      ),
    },
    phone: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Неверное количество символов",
        minLength(17)
      ),
      maxLength: helpers.withMessage(
        "Неверное количество символов",
        maxLength(17)
      ),
    },
    education_region_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    education_city_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    educational_establishment_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    faculty_id: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    course_level: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    speciality_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(3)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 100 символов",
        maxLength(100)
      ),
    },
    group: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Поле должно быть не менее 3 символов",
        minLength(1)
      ),
      maxLength: helpers.withMessage(
        "Поле должно быть не более 10 символов",
        maxLength(10)
      ),
    },
    year_ending_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    level_education: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
  },
  infoLFUser: {
    clothing_size: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    food_type: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    health_features: {
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 100",
        maxLength(100)
      ),
    },
  },
  infoLFStatement: {
    // video_card: {
    //   required: helpers.withMessage("Обязательно к заполнению", required),
    //   url: helpers.withMessage("Некорректная ссылка", url),
    // },
    community_represent: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    community_represent_other: {
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 100",
        maxLength(100)
      ),
    },
    community_represent_name: {
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 100",
        maxLength(100)
      ),
    },
    position_organization: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    position_organization_other: {
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 100",
        maxLength(100)
      ),
    },
    what_does_student_mean_you: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 2000",
        maxLength(2000)
      ),
    },
    purpose_travel: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 2000",
        maxLength(2000)
      ),
    },
    your_main_achievements: {
      required: helpers.withMessage("Обязательно к заполнению", required),
      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 2000",
        maxLength(2000)
      ),
    },
    hear_about_forum: {
      required: helpers.withMessage("Обязательно к заполнению", required),
    },
    hear_about_forum_other: {
      required: helpers.withMessage("Обязательно к заполнению", required),

      minLength: helpers.withMessage(
        "Количество символов не менее 2",
        minLength(2)
      ),
      maxLength: helpers.withMessage(
        "Количество символов не более 100",
        maxLength(100)
      ),
    },
  },
};
